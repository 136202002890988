import { ProjectCategory } from '~/enums';

import upkeepMain from './assets/upkeep.jpg';
import upkeepMainMobile from './assets/upkeep-mobile.jpg';
import upkeep1 from './assets/upkeep-1.jpg';
import upkeep1Mobile from './assets/upkeep-1-mobile.jpg';
import upkeep2 from './assets/upkeep-2.jpg';
import upkeep2Mobile from './assets/upkeep-2-mobile.jpg';
import routes from '~/routes';

const upkeepGeneralInfo = {
  name: 'Custom UpKeep Integrations',
  nameShort: 'Custom UpKeep Integrations',
  category: ProjectCategory.MAINTENANCE_SYSTEM,
  summary: 'Increasing the range of innovation in maintenance management',
  img: upkeepMain,
  imgMobile: upkeepMainMobile,
  projectPageUrl: routes.CASE_STUDIES_UPKEEP,
  description: '',
};

const upkeepCaseStudy = {
  generalInfo: upkeepGeneralInfo,
  about: {
    descriptionShort:
      'With over 2000 clients across various industries worldwide, UpKeep is among the leading providers of Computerised Maintenance Management Systems (CMMS).',
    descriptionLong:
      '<p>UpKeep app combines spreadsheets, calendars, to-do lists, etc. into one simple tool accessible everywhere in the palm of your hand. Its mobile-first approach makes asset, facility, property, and inventory management as smooth and easy as ever.</p><p>One of the software’s top strengths is the ability to integrate it with other tools, such as Slack, ZenDesk, or Oracle Netsuite.</p><p>UpKeep accomplishments in innovating the work of facilities and maintenance teams put the company on VCs radar – recently, they raised $36M in Series B financing round.</p>',
    types: ['Custom Code Integration'],
    image: {
      img: upkeep1,
      imgMobile: upkeep1Mobile,
    },
  },
  platform: {
    label: 'Platform',
    types: ['Mobile', 'Web'],
  },
  scope: {
    descriptionShort:
      'With our expert knowledge of UpKeep’s specifics and coding excellence, we are continuously expanding the software’s capabilities.',
    descriptionLong:
      "<p>UpKeep integrates seamlessly with many other management and communication tools. In some cases, however, making it work with software that is not fully supported yet, takes a couple of extra steps. That is what SwingDev provides to UpKeep’s clients, as an <b>official integration partner</b>.</p><p>Let us take <b>Certarus</b>, for instance. This leader in providing compressed natural gas solutions, was interested in implementing Samsara’s services to gather such data as location, engine hours, odometers, and other vehicle metrics. They needed these services to seamlessly integrate with UpKeep.</p><p>The Samsara team, trying to cement the deal, referred Certarus to SwingDev. As experts in custom integrations, we were happy to handle this task – we built a middle layer that pulls the data from Samsara and pushes it into the UpKeep maintenance management system.</p><p>SwingDev's developers <b>finished the task ahead of time</b>, and our point of contact from Certarus was so happy with the results that he recommended our services to other companies. This project alone got us <b>several more assignments</b> within a few weeks.</p>",
    image: {
      img: upkeep2,
      imgMobile: upkeep2Mobile,
    },
  },
  tldr:
    'As official partners of UpKeep we are making it possible to integrate a top CMMS software with other tools used by UpKeep’s clients. The process usually involves taking a deep dive into both UpKeep and third party system structures and writing advanced custom scripts.',
};

export default upkeepCaseStudy;
